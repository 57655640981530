import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const Login = lazy(() => import('./Login'))
const NewPassword = lazy(() => import('./new-pwd'))
const ForgotPassword = lazy(() => import('./ForgotPassword'))
const NotFound = lazy(() => import('./NotFound'))
const Register = lazy(() => import('./Register'))


export const AUTH_PATH = {
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  // RESET_PASSWORD: '/reset-password/:token',
  NOT_FOUND: '/404-not-found',
  LOGOUT: '/logout',
  NEW_PASSWORD: '/authentication/new-pwd/:token',
  REGISTER: '/authentication/register/:token',
}
const AuthRoutes : RouteObject[]= [
  {
    path: AUTH_PATH.LOGIN,
    element: <Login />,
  },
  {
    path: AUTH_PATH.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
  // {
  //   path: AUTH_PATH.RESET_PASSWORD,
  //   element: <ResetPassword />,
  // },
  {
    path: AUTH_PATH.NEW_PASSWORD,
    element: <NewPassword />,
  },
  {
    path: AUTH_PATH.NOT_FOUND,
    element: <NotFound />,
  },
  // {
  //   path: AUTH_PATH.LOGOUT,
  //   element: <Logout />,
  // },
  {
    path: AUTH_PATH.REGISTER,
    element: <Register />,
  },
]

export default AuthRoutes
