import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const Home = lazy(() => import('./Home'))
const CaseList = lazy(() => import('./CaseListing'))
const UserDetail = lazy(() => import('./UserDetail'))
const ProgressLog = lazy(() => import('./ProgressLog'))
const Attachments = lazy(() => import('./Attachments'))
const NotificationList = lazy(() => import('./Notification'))

export const HOME_PATH = {
  ROOT: '',
  HOME: '/home',
  CASE_LISTING: '/home/case-list/:projectID',
  USER_DETAIL: '/home/details/:saleID',
  PROGRESS_LOG: '/home/progress-log/:saleID',
  ATTACHMENT: '/home/attachments/:saleID/:projectID',
  NOTIFICATION: '/home/notification/list',
}
const HomeRoutes : RouteObject[]= [
  {
    path: '',
    element: <Home />,
  },
  {
    path: HOME_PATH.ROOT ,
    element: <Home />,
  },
  {
    path: HOME_PATH.HOME ,
    element: <Home />,
  },
  {
    path: HOME_PATH.CASE_LISTING ,
    element: <CaseList />,
  },
  {
    path: HOME_PATH.USER_DETAIL ,
    element: <UserDetail />,
  },
  {
    path: HOME_PATH.PROGRESS_LOG ,
    element: <ProgressLog />,
  },
  {
    path: HOME_PATH.ATTACHMENT ,
    element: <Attachments />,
  },
  {
    path: HOME_PATH.NOTIFICATION ,
    element: <NotificationList />,
  },
]

export default HomeRoutes
