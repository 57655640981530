import { getAccessToken, setAccessToken } from 'AccessToken'
import { onError } from '@apollo/client/link/error'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createUploadLink } from 'apollo-upload-client'
import { setLoading } from 'containers/helper/loadingHelper'
import jwtDecode from 'jwt-decode'
import { navigateTo } from './containers/helper/navigationSubjectHelper'
import { AUTH_PATH } from './containers/modules/Authentication/Routes'
import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  Observable,
  HttpLink,
} from '@apollo/client'

const propertyXSoclicitorNodeUrl = import.meta.env.VITE_API_URL
export const GoogleAPIKey = import.meta.env.VITE_VITE_GOOGLE_API_KEY
let apiNum: number = 0

const refreshTokenLink = new TokenRefreshLink({
  accessTokenField: 'accessToken',

  isTokenValidOrUndefined: () => {
    const token = getAccessToken()

    if (!token) {
      return true
    }

    try {
      const { exp } = jwtDecode(token)
      return Date.now() < exp * 1000
    } catch (err) {
      return false
    }
  },
  fetchAccessToken: () => {
    return fetch(`${propertyXSoclicitorNodeUrl}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify({
        query: `
                query refreshToken {
                    refreshToken
                  }
                `,
      }),
    })
  },
  handleFetch: accessToken => {
    setAccessToken(accessToken)
  },
  handleError: err => {
    // console.warn('Your refresh token is invalid. Try to relogin')

    sessionStorage.clear()
    localStorage.clear()
    navigateTo(AUTH_PATH.LOGIN)
  },
})

const errHandler = onError(
  ({ graphQLErrors, networkError, response, operation, forward }) => {
    // all error will be thrown here

    console.log('graphQLErrors', graphQLErrors)

    if (graphQLErrors) {
      //handle graphql error as needed

      let unauthenticated = false
      for (let e of graphQLErrors) {
        if (e.extensions?.code == 'UNAUTHENTICATED') {
          //sessionStorage.clear()
          setAccessToken('')
          unauthenticated = true
        }
      }

      if (unauthenticated) {
        return new Observable(observer => {
          fetch(`${propertyXSoclicitorNodeUrl}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({
              query: `
						query refreshToken {
							refreshToken {
								accessToken
								refreshToken
							}
						}
										`,
            }),
          })
            .then(async res => {
              const data = await res.json()
              const responseData = data.data.refreshToken

              const newAccessToken = responseData.accessToken

              if (newAccessToken) {
                setAccessToken(`${newAccessToken}`)
              }

              operation.setContext({
                headers: {
                  authorization: `Bearer ${newAccessToken || getAccessToken()}`,
                  projectid:
                    localStorage.getItem('pid') !== null
                      ? localStorage.getItem('pid')
                      : null,
                },
              })
            })
            .then(() => {
              const subscriber = {
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              }
              forward(operation).subscribe(subscriber)
            })
            .catch(observer.error.bind(observer))
        })
      }
    }
    if (networkError) {
      // handle network error as needed
      console.log(`Network Error: ${networkError}`)
      navigateTo(AUTH_PATH.NOT_FOUND)
    }
  }
)

const deductApiCount = () => {
  if (apiNum > 0) {
    --apiNum
  }
  if (apiNum === 0) {
    setLoading(false)
  }
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle: any
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken()
          setLoading(true)
          apiNum++
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `Bearer ${accessToken}`,
                projectid:
                  localStorage.getItem('pid') !== null
                    ? localStorage.getItem('pid')
                    : null,
              },
            })
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        deductApiCount()
        if (handle) handle.unsubscribe()
      }
    })
)

const responseData = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    if (apiNum > 0) {
      --apiNum
    }
    let resultResponse = JSON.parse(JSON.stringify(response))
    if (apiNum === 0) {
      setLoading(false)
    }
    return resultResponse
  })
})

const uploadLink = createUploadLink({
  uri: propertyXSoclicitorNodeUrl,
  credentials: 'include',
})

const httpLink = new HttpLink({
  uri: propertyXSoclicitorNodeUrl,
  credentials: 'include',
})

const cache = new InMemoryCache({})

// const apolloCache = new UploadInMemoryCache({
//   addTypename: false,
// });

// export const Uploadclient = new UploadApolloClient({
//   cache: apolloCache,
//   link: ApolloLink.from([requestLink, uploadLink]),
// });

export const client = new ApolloClient({
  link: ApolloLink.from([
    errHandler,
    requestLink,
    responseData,
    uploadLink,
    refreshTokenLink,
    httpLink,
  ]),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
})
