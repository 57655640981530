import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const Profile = lazy(() => import('./Profile'))
const EditProfile = lazy(() => import('./EditProfile'))
const ProfileChangePassword = lazy(() => import('./ProfileChangePassword'))

export const ACCT_PATH = {
  PROFILE: 'profile',
  EDIT_PROFILE: 'edit-profile',
  PROFILE_CHANGE_PASSWORD: 'reset-password',
}
const AcctRoutes : RouteObject[] = [
  {
    path: '' ,
    element: <Profile />,
  },
  {
    path: ACCT_PATH.PROFILE,
    element: <Profile />,
  },
  {
    path: ACCT_PATH.EDIT_PROFILE ,
    element: <EditProfile />,
  },
  {
    path: ACCT_PATH.PROFILE_CHANGE_PASSWORD ,
    element: <ProfileChangePassword />,
  },
]

export default AcctRoutes
